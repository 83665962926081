import React, { useEffect, useRef, useContext, useState } from "react"
import { graphql } from "gatsby"
import gsap from "gsap"
import DesignSection from "../components/organisms/designSection/designSection"
import SEO from "../components/seo"
import { CursorContext } from '../context/cursorContext'

const DesignItem = ({
  data,
  location,
  navigate,
  pageContext,
  transitionStatus,
  entry,
  exit,
}) => {
  const { design } = data
  const { cursorConfig, setCursor } = useContext(CursorContext)
  const wrapper = useRef()
  const [transition, setTransition] = useState('entering')

  useEffect(() => {
    transitionStatus !== 'entered' && setTransition(transitionStatus)
  }, [])

  useEffect(() => {
    if (transition === "entering") {
      gsap.fromTo(
        wrapper.current,
        {
          y: entry.state.direction === "up" ? "-100vh" : "100vh",
        },
        {
          y: "0vh", duration: 1,
        }
      )
    }
    if (transitionStatus === "exiting") {
      gsap.fromTo(
        wrapper.current,
        { y: "0vh" },
        {
          y: exit.state.direction === "up" ? "100vh" : "-100vh",
          duration: 1,
          onComplete: () => {
            setCursor({ blocked: false })
          },
        }
      )
    }
  }, [transitionStatus, entry, exit])

  return (
        <div className="top-wrapper" ref={wrapper}>
          <SEO
            title={`${design.acf.meta_title || design.title}`}
            description={design.acf.meta_desc}
            thumb={design.acf?.thumbnail?.sourceUrl}
          />
          <DesignSection
            design={design}
            pageContext={pageContext}
          />
        </div>
  )
}

export default DesignItem

export const query = graphql`
  query DesignItem($id: String!) {
    design: wpDesign(id: {eq: $id}) {
        id
        title
        uri
        slug
        acf: designMetaFields {
          textLower
          textUpper
          thumbnail {
            sourceUrl
            localFile {
              ...AhoyImage
            }
          }
          meta_title: textUpper
          meta_desc: textLower
        }
        designFlexFields {
          slideroptions {
            autoplay
            transitionStyle
            transitionTime
          }
          slides {
            type: __typename
            ... on WpDesign_Designflexfields_Slides_Textblock {
              sideDescription {
                lower
                upper
              }
              text
              textBackgroundColor {
                custom
                presets
              }
            } 
            ... on WpDesign_Designflexfields_Slides_VideoBlock {
              video
              textoverlay
              format {
                boundaries
                fieldGroupName
                ratio {
                  fieldGroupName
                  height
                  width
                }
                mute
                shadow
                size
              }
              sideDescription {
                lower
                upper
              }
            }
            ... on WpDesign_Designflexfields_Slides_Image {
              fieldGroupName
              format {
                boundaries
                fieldGroupName
                ratio {
                  fieldGroupName
                  height
                  width
                }
                shadow
                size
              }
              sideDescription {
                lower
                upper
              }
              image {
                sourceUrl
                localFile {
                  ...AhoyImage
                }
              }
              textoverlay
            }
            ... on WpDesign_Designflexfields_Slides_StopMotion {
              fieldGroupName
              speed
              format {
                boundaries
                fieldGroupName
                ratio {
                  fieldGroupName
                  height
                  width
                }
                shadow
                size
              }
              frames {
                sourceUrl
                localFile {
            ...AhoyImage
          }
              }
              sideDescription {
                fieldGroupName
                lower
                upper
              }
            }
          }

        popUp {
          text
          background {
            custom
            presets
            popupImage {
              sourceUrl
              mediaDetails {
                sizes {
                  width
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`
